<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        disable-sort
        :loading="loading"
        :server-items-length="entitiesCount"
        :items-per-page="itemsPerPage"
        :footer-props="{ 'itemsPerPageOptions': [10, 15, 30] }"
        @pagination="paginate"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Акции и скидки</v-toolbar-title>
                <v-spacer />
                <v-btn color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon> Добавить
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
            {{ DISCOUNT_TYPE_TITLE[item.type] }}
        </template>
        <template v-slot:[`item.delivery_type`]="{ item }">
            {{ deliveryTitles[item.delivery_type] }}
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
            <span :class="`true-false-label ${getBadge(item.enabled)}`">
                &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
            </span>
        </template>
        <template v-slot:[`item.title`]="{ item }">
            <span class="title">{{ item.title }}</span>
        </template>
        <template v-slot:[`item.site`]="{ item }">
            {{ item.site ? item.site.title : '' }}
        </template>
        <template v-slot:[`item.open`]="{ item }">
            <a :href="`/discounts/${item.id}/detail`">
                <v-icon color="primary">mdi-eye</v-icon>
            </a>
        </template>
    </v-data-table>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';
    
    import { DISCOUNT_TYPE_TITLE } from '../../vars';

    export default {
        name: 'DiscountsList',
        components: {
        },
        data: () => ({
            DISCOUNT_TYPE_TITLE,
            page: 1,
            itemsPerPage: 15,
            loading: true,
            headers: [
                { text: 'Дата', value: 'created_at' },
                { text: 'Активность', value: 'enabled' },
                { text: 'Сайт', value: 'site' },
                { text: 'Заголовок', value: 'title' },
                // { text: 'Тип', value: 'type' },
                { text: 'Действует', value: 'delivery_type' },
                { text: '', align: 'right', value: 'open' },
            ],
            deliveryTitles: {
                all: 'На доставку и самовывоз',
                delivery: 'Только на доставку',
                pickup: 'Только на самовывоз'
            }
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('discounts', ['entities', 'entitiesCount']),
        },
        async mounted() {
            this.fetch();
        },
        methods: {
            async fetch() {
                this.loading = true;
                await store.dispatch('discounts/fetch', { page: this.page, itemsPerPage: this.itemsPerPage });
                this.loading = false;
            },
            async paginate({ page, itemsPerPage }) {
                this.page = page;
                this.itemsPerPage = itemsPerPage;
                await this.fetch();
            },
            async create() {
                await this.$router.push({ name: 'discounts-create' });
            },
            async open(id) {
                await this.$router.push({ name: 'discounts-detail', params: { id } });
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
        }
    }
</script>

<style scoped>
    .title {
        font-size: 16px !important;
        line-height: 18px;
        font-weight: 700;
    }
</style>