import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities,"disable-sort":"","loading":_vm.loading,"server-items-length":_vm.entitiesCount,"items-per-page":_vm.itemsPerPage,"footer-props":{ 'itemsPerPageOptions': [10, 15, 30] }},on:{"pagination":_vm.paginate},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Акции и скидки")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Добавить ")],1)],1)]},proxy:true},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'DD.MM.YYYY HH:mm'))+" ")]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.DISCOUNT_TYPE_TITLE[item.type])+" ")]}},{key:`item.delivery_type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.deliveryTitles[item.delivery_type])+" ")]}},{key:`item.enabled`,fn:function({ item }){return [_c('span',{class:`true-false-label ${_vm.getBadge(item.enabled)}`},[_vm._v("  "+_vm._s(item.enabled ? 'Да' : 'Нет')+"  ")])]}},{key:`item.title`,fn:function({ item }){return [_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))])]}},{key:`item.site`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.site ? item.site.title : '')+" ")]}},{key:`item.open`,fn:function({ item }){return [_c('a',{attrs:{"href":`/discounts/${item.id}/detail`}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }